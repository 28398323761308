<template>
  <BrToolBar @search="buscar" :configFilter="configFilter">
    <modal></modal>
    <modalIndividual></modalIndividual>
    <template v-slot:btnSelect>
      <slot name="btnSelect" />
    </template>
  </BrToolBar> 
</template>

<script>
/**
 * MIXIN
 */
import toolbar from '@/views/mixins/toolbar'

export default {
  props: ['select'],
  name: `BrToolBarCadastroRole`,
  mixins: [toolbar],
  components: { 
    BrToolBar: () => import('@/views/components/toolbar'),
    modalIndividual: () => import('./modalCadastroIndividual'),
    modal: () => import('./modalCadastro')
  }
}
</script>